// import React from "react"
// import { Link, StaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

// import Grid from "@material-ui/core/Grid"
// import Typography from "@material-ui/core/Typography"
// import { useSpring, animated } from "react-spring"

// import Image from "../components/image"
// import SEO from "../components/seo"

// const ImageProfile = () => (
//   <StaticQuery
//     query={graphql`
//       query {
//         placeholderImage: file(
//           relativePath: { eq: "media/Website_wall_photo.png" }
//         ) {
//           childImageSharp {
//             fluid(maxWidth: 580) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     `}
//     render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
//   />
// )

// const ImgTool = props => (
//   <div
//     style={{
//       width: 40,
//       height: 40,
//       marginRight: 14,
//       backgroundImage: `url('${props.src}')`,
//       backgroundSize: "contain",
//     }}
//   />
// )

// const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
// const trans1 = (x, y, m = 0.02) => `translate3d(${x * m}px,${y * m}px,0)`
// const trans2 = (x, y, m = 0.08) => `translate3d(${x * m}px,${y * m}px,0)`

// const AboutPage = () => {
//   const [props, set] = useSpring(() => ({
//     xy: [0, 0],
//     config: { mass: 10, tension: 550, friction: 140 },
//   }))

//   return (
//     <div onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
//       <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />

//       {/*
//       <h1>Hi people</h1>
//       <p>Welcome to your new Gatsby site.</p>
//       <p>Now go build something great.</p>
//       <div style={{ maxWidth: `800px`, marginBottom: `1.45rem` }}>
//         <Image />
//       </div>
//       */}

//       <Grid
//         container
//       >
//         <Grid item xs={12} sm={5} className="about-section">
//           <Grid container direction="column" className="about-content">
//             <div
//               style={{
//                 fontSize: 24,
//                 fontWeight: "bold",
//               }}
//             >
//               I'm a 3d Artist and
//               <br />
//               Motion grapher
//             </div>

//             <div style={{ marginTop: "3vh", marginBottom: "7vh" }}>
//               <div
//                 style={{
//                   padding: "4px 0",
//                   border: "1px solid white",
//                   borderLeft: "none",
//                   borderRight: "none",
//                   display: "inline-block",
//                   cursor: "pointer",
//                 }}
//                 children="contact me"
//               />
//             </div>

//             <p className="lighter">
//               3D Artist & Motion Grapher. Creo render fotorealistici, animazioni
//               video in 3D e motion graphic, realizzo esperienze web 3D
//               interattive per il tuo brand, prototipo le tue idee tramite stampa
//               3D.
//             </p>

//             <Grid container direction="row" style={{ marginTop: 80 }}>
//               <ImgTool src={require("../images/logo-photoshop.png")} />
//               <ImgTool src={require("../images/logo-photoshop.png")} />
//               <ImgTool src={require("../images/logo-photoshop.png")} />
//               <ImgTool src={require("../images/logo-photoshop.png")} />
//               <ImgTool src={require("../images/logo-photoshop.png")} />
//             </Grid>
//           </Grid>
//         </Grid>

//         <Grid item xs={12} sm={5} className="about-section">
//           <div className="about-content">
//             <animated.div
//               style={{
//                 width: 400,
//                 height: 400,
//                 transform: props.xy.interpolate(trans1),
//                 //opacity: 0.2,
//               }}
//             >
//               <ImageProfile />
//             </animated.div>

//             <animated.div
//               children="hi"
//               //className="neon-text"
//               style={{
//                 //fontFamily: "Monoton",
//                 fontFamily: "Monospace",
//                 transform: props.xy.interpolate(trans2),
//                 //width: 10,
//                 height: 380,
//                 fontSize: 380,
//                 //background: "rgba(255, 100, 0, 0.7)",
//                 position: "absolute",
//                 fontWeight: "100",
//                 left: 0,
//                 right: 0,
//                 margin: "auto",
//                 top: 0,
//                 bottom: 0,
//                 lineHeight: "100%",
//                 whiteSpace: "nowrap",
//                 textAlign: "center",
//               }}
//             />
//           </div>
//         </Grid>

//         {/*<Link to="/page-2/">Go to page 2</Link>*/}
//       </Grid>
//     </div>
//   )
// }

// export default AboutPage

import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import { Grid } from "@material-ui/core"
import Img from "gatsby-image"

import aiLogo from "../images/ai.svg"
import psLogo from "../images/ps.svg"
import aeLogo from "../images/ae.svg"
import mayaLogo from "../images/maya.svg"
import c4dLogo from "../images/c4d.svg"
import { StaticQuery, graphql } from "gatsby"

const technologies = [
  {
    name: "Adobe Photoshop",
    image: psLogo,
  },
  {
    name: "Adobe Illustrator",
    image: aiLogo,
  },
  {
    name: "Adobe After Effects",
    image: aeLogo,
  },
  {
    name: "Autodesk Maya",
    image: mayaLogo,
  },
  {
    name: "Cinema 4D",
    image: c4dLogo,
  },
]

const AboutPage = props => {
  return (
    <div>
      <SEO
        title="About"
        keywords={[
          "3dartist",
          "3dmodeling",
          "3d",
          "photorealism",
          "shading",
          "productstudy",
          "3drender",
          "motiongraphics",
          "motiongraphicsdesign",
          "video",
          "videomaking",
          "3danimation",
          "animation",
        ]}
      />
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ overflowY: "scroll", height: "100vh" }}
      >
        <Grid item xs={12} lg={6} className="about-section">
          {/* <div className="about-content"> */}
          <StaticQuery
            query={graphql`
              query {
                file(relativePath: { eq: "profile-pic.jpg" }) {
                  childImageSharp {
                    id
                    fluid(maxWidth: 900, quality: 95) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            `}
            render={data => {
              console.log(90999, data)
              return (
                <div className="about-image-container">
                  <Img
                    alt="Andrea Munegato"
                    className="about-image"
                    fluid={data.file.childImageSharp.fluid}
                  />
                </div>
              )
            }}
          />
          {/* </div> */}
        </Grid>
        <Grid item xs={12} lg={6} className="about-section">
          <p className="about-title">
            I'm a 3D artist and
            <br />
            motion grapher
          </p>
          <a href="mailto:info@andreamunegato.it" className="about-contact-me">
            <span>contact me</span>
          </a>
          <p className="about-description">
            3D Artist & Motion Grapher. Creo render fotorealistici, animazioni
            video in 3D e motion graphic, realizzo esperienze web 3D interattive
            per il tuo brand, prototipo le tue idee tramite stampa 3D.
          </p>
          <div className="about-technologies">
            {technologies.map((item, index) => (
              <img
                key={index}
                className="about-tech"
                src={item.image}
                alt={item.name}
              />
            ))}
          </div>
          <p style={{ fontWeight: 700, fontFamily: "HelveticaNeue" }}>
            Dicono di me
          </p>
          <p className="about-description" style={{ marginBottom: 24 }}>
            "Il lavoro fatto con A.M. ci ha permesso di alzare la qualità dei
            nostri video, delle pagine del nostro sito web e dei nostri post sui
            social, incrementando l'interesse da parte del pubblico. È una
            persona attenta ai dettagli, competente e affidabile."
          </p>
          <p className="about-referencer">
            Roberto Ricci - Ricci International S.r.l.
          </p>
          {/* </div> */}
        </Grid>
      </Grid>
    </div>
  )
}

export default AboutPage
